@charset "utf-8";

// Import a Google Font
//@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');
//$family-sans-serif: 'Nunito', sans-serif;

//@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');
//$family-sans-serif: 'Muli', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Libre Baskerville:300,400,600,700');
$family-sans-serif: 'Quicksand', sans-serif;


// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400, 600,700');
// $family-sans-serif: 'Poppins', sans-serif;


// Set your brand colors
//$studycle-green: #0AABAA;
//$studycle-green: #0FB582;


// Scuola (https://scuola.vamtam.com/)
$studycle-yellow: rgb(255, 193, 7);
$studycle-blue: rgb(0, 91, 185);


//$studycle-green: #285F9F;
$studycle-green: #2DB798; // logo
$studycle-green-light: rgba(45, 185, 154, 0.3);


$studycle-red: #D04561; // logo red
//$studycle-blue: #285F9F; // logo blue
//$studycle-yellow: #FCC033; // logo yellow


// rgba(10, 171, 170, 1)

$studycle-dark: #4C5C69;
// rgba(76, 92, 105, 1)

//$studycle-text-color-primary: #285F9F;
//$studycle-text-color-primary: #2DB798; // blue default
$studycle-text-color-primary: $studycle-blue;


$studycle-light-green: #E8F7F4;
$studycle-light-green-2: rgba(45, 183, 152, 0.1);

/*
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
*/
// Update Bulma's global variables

/*
$grey-dark: $brown;
$grey-light: $beige-light;
*/
$primary: $studycle-yellow;
$primary: $studycle-blue;
//$primary: #D04561; // logo red
//$primary: #285F9F; // logo blue
//$primary: #FCC033; // logo yellow


/*
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;
*/

// Update some of Bulma's component variables
/*
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
*/
/*
.wrap-text {
    overflow-wrap: break-word;
}

.card-footer {
    span {
        cursor: pointer;
    }
}
*/

//$title-color: #173757;
$title-color: $studycle-blue;

$title-weight: 700;
$subtitle-line-height: 2;
$subtitle-negative-margin: -1.25rem;

$navbar-item-color: #161616;
//$navbar-item-hover-color: $studycle-text-color-primary;
//$navbar-item-active-color: $studycle-text-color-primary;
$navbar-item-hover-color: $studycle-blue;
$navbar-item-active-color: $studycle-blue;


$navbar-item-hover-background-color: transparent;
//$navbar-dropdown-item-hover-background-color: $studycle-green-light;
//$navbar-dropdown-background-color: $studycle-green;

//$card-footer-border-top: 1px solid $primary;
$card-footer-border-top: 0;
$card-content-padding: 1.5rem 0 0;

// Import only what you need from Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/grid/columns.sass';

@import '../../node_modules/bulma/sass/components/navbar.sass';
@import '../../node_modules/bulma/sass/components/modal.sass';
@import '../../node_modules/bulma/sass/components/card.sass';

@import '../../node_modules/bulma/sass/components/media.sass';
@import '../../node_modules/bulma/sass/components/tabs.sass';

@import '../../node_modules/bulma/sass/elements/box.sass';
@import '../../node_modules/bulma/sass/elements/button.sass';
@import '../../node_modules/bulma/sass/elements/container.sass';
@import '../../node_modules/bulma/sass/elements/icon.sass';
@import '../../node_modules/bulma/sass/elements/image.sass';
@import '../../node_modules/bulma/sass/elements/notification.sass';
@import '../../node_modules/bulma/sass/elements/progress.sass';
@import '../../node_modules/bulma/sass/elements/title.sass';
@import '../../node_modules/bulma/sass/elements/table.sass';

@import '../../node_modules/bulma/sass/layout/hero.sass';
@import '../../node_modules/bulma/sass/layout/section.sass';

@import '../../node_modules/bulma/sass/form/_all.sass';


@import 'common';
@import 'header';
@import 'footer';

@import 'accordion';
@import 'card';
@import 'list';
@import 'hero';
@import 'button';
@import 'tabs';
@import 'text';

@import 'pages/home';
@import 'pages/about-us';
@import 'pages/membership';
@import 'pages/courses';
@import 'pages/programmes';
@import 'pages/location';

@import 'forms/login';