.login {
    margin-top: 2.5rem;
    
    .box {
        margin-bottom: 2.5rem;

        h3 {
            text-align: center;
        }

        .button {
            padding: 8px 2rem;
        }

        .field {
            margin-bottom: 1.5rem;

            &:last-child {
                margin-top: 2rem;
                margin-bottom: 0.75rem;
            }
        }

        .errors {
            margin-bottom: 1rem;
            //padding: 0 1rem;
            color: $studycle-red;
        }
    }
}