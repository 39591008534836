.card.pricing {
    .card-header {
        flex-direction: column;
        padding: 1.25rem;
        text-align: center;
        //box-shadow: none;

        .title {
            font-size: 1.5rem;
            font-weight: 600;

        }

        .subtitle {
            line-height: 1.25rem;
            font-size: 1rem;
            font-weight: 400;
            color: #444;
        }
    }

    .card-content {
        padding: 1rem 1.25rem 1.25rem;

        ul {
            list-style-type: none;
            text-align: center;
            font-size: 1rem;

            li {
                span {
                    font-weight: 600;
                }

                &:not(:last-child) {
                    margin-bottom: 0.75rem;
                    padding-bottom: 0.75rem;
                    border-bottom: 1px solid #ddd;
                }
            }


        }
    }
}

.member-benefits {
    padding: 0 5rem;
    text-align: center;
    margin-top: 1rem;

    > div {
        margin-bottom: 2.5rem;
    }

    span {
        font-size: 2.75rem;
    }

    p {
        font-size: 1rem;
    }
}