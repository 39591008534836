* {
    box-sizing: border-box;
}
 
html, body {
    //height: 100%;
    //width: 100%;
}


body {
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 1rem;
    /*
    font-size: 16px;
    line-height: 1.6;
    color: #47018b;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    */
}
  

#root {
    //display: flex;
    //flex-direction: column;
    //height: 100%;
}

.App {
    //flex: 1;
    //display: flex;
    //flex-direction: column;

    //height: 100%;
    //width: 100%;
}

main {
    //flex: 1;
}

.scl-vertcard-container {
    padding-bottom: 4.5rem;
}

.button {
    &.is-circle {
        align-items: center;
        display: inline-flex;
        justify-content: center;

        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
    }
}

.is-scl-green {
    background: $studycle-green;

    .title {
        color: #fff;
    }

    .subtitle {
        color: rgba(255, 255, 255, 0.8);
    }
}

.is-scl-green-light {
    background: url('/static/img/wave-1.png') $studycle-green-light bottom / contain no-repeat;
    //background: url('/static/img/wave-1.png') bottom / contain no-repeat;
    padding-bottom: 5rem;
}

.is-scl-red {
    background: $studycle-red;
}

.is-scl-blue {
    background: $studycle-blue;
}

.is-scl-yellow {
    background: $studycle-yellow;
}

.is-scl-early {
    color: $studycle-green !important;
}

.is-scl-young {
    color: $studycle-red !important;
}

.is-scl-adult {
    color: $studycle-yellow !important;
}

.target-age {
    display: inline-block;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }

    span {
        position: relative;
        top: 2px;
        margin-right: 0.1rem;
    }
}

p {
    font-size: 1.25rem;
}

@media screen and (min-width: 1025px) {
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block !important;
    }
    .navbar-item.is-hoverable:focus-within .navbar-dropdown {
        display: none;
    }
}