footer {
    //background: $studycle-green;
    //background: #4C5C69;
    //color: white;
    //background-color: #f7f9f9;
    background-color: #ffffff;

    //border-top: 1px solid rgba(191, 193, 194, 0.5);
    //border-top: 3px solid rgb(247, 248, 253);
    border-top: 2px solid #edf0f2;
    

    //margin-top: 1.5rem;

    //color: #707070;
    //color: #041322;
    color: rgb(123, 126, 133);
    letter-spacing: 0.2px;

    font-size: 1rem;
    line-height: 1.5rem;
    
    > div.container {
        //border-top: 1px solid rgb(216, 216, 216);

        &:first-child {
            padding: 2rem 2rem 0;
        }

        .logo {
            img { 
                max-height: 2.75rem;
            }

            margin-bottom: 1rem;
        }

        h6.title {
            color: #201e25;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin-bottom: 1rem;

            &:not(:first-child) {
                margin-top: 1.5rem;
            }
        }

        > div:not(:first-child) { //:not(:last-child) {
            //padding-top: 3rem;

            > span {
                display: block;
                margin-bottom: 0.15rem;
            }
        }

        a {
            color: rgb(123, 126, 133);
            &:hover {
                color: #444;
            }
        }
    }

    ul.social-media {
        $social-media-color: #d2d2d2;

        margin-top: 0.75rem;

        li {
            list-style: none;
            margin: 0 10px 0 0;
            display: inline-block;

            a {
                height: 2rem;
                width: 2rem;
                display: block;
                float: left;
                border: 1px solid $social-media-color;
                border-radius: 50%;
                position: relative;

                span {
                    color: $social-media-color;
                    position: absolute;
                    font-size: 1rem;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                }

                &:hover {
                    &.red {
                        border-color: $studycle-red;
                        background: $studycle-red;
                    }

                    &.yellow {
                        border-color: $studycle-yellow;
                        background: $studycle-yellow;
                    }
                }

                &:hover {
                    border-color: $studycle-blue;
                    background: $studycle-blue;

                    span {
                        //color: $studycle-green;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .copytext {
        // border-top: 1px solid rgba(191, 193, 194, 0.3);
        //background: $studycle-green;
        border-top: 2px solid rgb(247, 248, 253);
        padding: 1.5rem 0;
        font-weight: 400;
    }
}