.page-content {
    ol, ul {
        list-style-position: inside;
        margin-top: 0.5rem;
        margin-left: 0.25rem;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }
}

ol {
    &.is-scl-nice-list {
        list-style: none;
        counter-reset: item;

        li {
            counter-increment: item;
            margin-bottom: 2.75rem;

            &:before {
                content: counter(item);
                border-radius: 0.25rem;
                background: $studycle-light-green;
                color: $studycle-text-color-primary;
                font-weight: 700;

                width: 1.75rem;
                height: 1.75rem;

                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;

                justify-content: center;
                align-items: center;

                float: left;
            }
            
            > div {
                margin-left: 2.75rem;

                .title { 
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
