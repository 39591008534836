header {
    box-shadow: 0 3px 50px 0 rgba(105,102,130,.1);


    .navbar {
        $navbar-item-padding: 0.5rem 0.75rem;
        // background-color: #0AABAA;

        .navbar-brand {
            .navbar-item img {
                margin: 1rem 0;
                max-height: 2rem;
            }
        }

        .navbar-burger {
            height: auto;
        }

        .navbar-item.has-dropdown {
            align-items: center;
            padding: 0.5rem 0;
        }

        .navbar-link,
        .navbar-item {
            //color: #161616;
            font-weight: 600;
            //font-size: 16px;
            //font-size: 0.9rem;
            font-size: 1rem;
            //text-transform: uppercase;
            //letter-spacing: 1px;
            line-height: 1.6;
            //padding: $navbar-item-padding;

            &.is-active {
                font-weight: 700;
            }

            &:hover .navbar-link.is-active {
                //color: #fff;
            }

            &:hover {
                //color: #fff;
                //background: $studycle-green;
                //border-radius: 25px;
            }
        }

        .navbar-dropdown {
            //border: 1px solid #dedede;
            border: 0;
            padding: 0;
            overflow: hidden;

            box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.05);
            //color: #4a4a4a;

            .navbar-item:hover {
                //border

            }
        }

        .navbar-item:not(:last-child) {
            //margin-right: 20px;
        }

        .navbar-burger {
            color: $primary;

            span {
                height: 2px;
            }
        }

        .button {
            padding: 8px 1.5rem;
            font-size: 0.75rem;
        }

        @media screen and (max-width: $desktop - 1px) {
            .navbar-dropdown {
                margin: 0 1rem;
            }
        }

        @media screen and (min-width: $desktop) {
            .navbar-item.has-dropdown {
                padding: $navbar-item-padding;
            }
            
            .navbar-dropdown {
                margin-top: -1rem;
                border-radius: 0.2rem;
                //color: red
            }
        }

    }
}
