.accordions {
    margin-bottom: 2.5rem;
}

.accordion__section {
    display: flex;
    flex-direction: column;

    border-bottom: 2px solid #ffffff;

    /* Style the buttons that are used to open and close the accordion panel */
    .accordion {
        //background-color: #eee;
        background-color: rgba(0, 91, 185, 0.1);
        //rgba(0, 91, 185, 0.3); // blue
        //rgba(255, 193, 7, 0.3); // yellow

        color: #444;

        cursor: pointer;
        padding: 18px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;

        &:hover,
        &.active {
            //background-color: #ccc;
            background-color: $studycle-yellow;

//             color: #fff;
//             svg path {
//                 fill: #fff !important;
//             }
        }
    }

    .accordion__title {
        font-weight: 600;
        font-size: 1.25rem;
        margin: 0 !important;
    }

    /* Style the accordion chevron icon */
    .accordion__icon {
        margin-left: auto;
        transition: transform 0.6s ease;
    }

    /* Style to rotate icon when state is active */
    .rotate {
        transform: rotate(90deg);
    }

    .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;

        p {
            margin-bottom: 1.25rem !important;
        }
    }

    /* Style the accordion content text */
    .accordion__text {
        padding: 1rem;
    }
}
