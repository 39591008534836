.location {
    .title.is-4 {
        color: $studycle-blue;
    }

    p {
        margin-bottom: 0 !important;
    }

    .address {
        margin-top: 1rem;
        p {
            &:first-child {
                font-weight: 700;
            }
        }
    }
}