
.our-services {
    padding: 0 5rem;
    text-align: center;

    > div {
        margin: 0 0 1.5rem !important;
    }

    span {
        font-size: 2.75rem;
    }

    .desc {
        align-self: flex-start;
        text-align: left;
    }

    p {
        font-size: 1rem;
    }
}