.hero {
    background: url('/static/img/banner-1.jpg') center / cover;
    // background: url('/static/img/gallery/girl-reading-book.jpg') center / cover;
    // background: url('/static/img/gallery/learning-group.jpg') center / cover;

    //border-radius: 0.5rem 0.5rem 0 0;
    border-radius: 0;

    overflow: hidden;

    .hero-main.hero-body {
        background:
            url('/static/img/hero-mask-1.png')
            rgba(31, 68, 112, 0.7) bottom / contain no-repeat;

        .mask-1 {
            width: 100%;
            height: 100%;
            
            // figure {
            //     position: absolute;
            //     bottom: -1px;
            // }
        }

        .hero-text {
            // display: -webkit-box;
            // display: -moz-box;
            // display: -ms-flexbox;
            // display: -webkit-flex;
            // display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;

            left: 5%;
            width: 90%;

            h1 {
                margin-bottom: 30px;
                font-size: 45px !important;
                //font-family: 'Libre Baskerville',
            }

            .title,
            .subtitle {
                line-height: 1.25;
            }
        }
    }
}
  
@media screen and (min-width: $tablet), print {
.hero {
    .hero-main.hero-body {
        background:
            url('/static/img/hero-mask-1.png')
            rgba(31, 68, 112, 0.4) bottom / contain no-repeat;

        .hero-text {
            width: 60%;
            align-items: flex-start;

            .subtitle {
                line-height: 1.5;
                text-align: left;
            }
        }
    }
}
}
  
@media screen and (max-width: $tablet - 1px), print {
.hero {
    border-radius: 0;
    
    .hero-main.hero-body {
        padding: 12rem 0;

        .hero-text {
            .title,
            .subtitle {
                text-align:center;
            }
        }
    }
}
}


@media screen and (min-width: $desktop), print {
.hero {
    .hero-main.hero-body {
        .hero-text {
            width: 45%;
        }
    }
}
}