.card {
    // box-shadow: none;
    $card-overflown-top: -2.25rem;
    $card-padding: 1.5rem;
    margin-bottom: 1.5rem;
    
    .card-footer {
        //padding: $card-padding 0;
        padding: $card-padding 0;
    }

    //&:not(.banner-card):not(.featured-card) {
    &.vert-card,
    &.horz-card,
    &.flat-card,
    &.flat-color-card {
        border: 1px solid #E5E5E5;
        border-radius: 0.5rem;

        // &:hover {
        //     //background: rgba(45, 183, 152, 0.1);
        //     //background: rgba(15, 181, 130, 0.6);
        //     //background: $studycle-green;
        //     //background: $studycle-light-green;

        //     .title, .subtitle {
        //         //color: $studycle-text-color-primary;
        //     }

        //     .card-footer {
        //         //border-top: 1px solid $studycle-text-color-primary;
        //     }
        // }
    }

    &.banner-card {
        padding: 2rem 3rem 4rem;
        //border: 0.3rem solid $studycle-blue;
        //background: rgba(40, 95, 159, 0.3);
        background: $studycle-yellow;
        
        box-shadow: none;

        //background: rgb(45, 183, 152) url('/static/img/gallery/learning-group.jpg')  center / cover;
    }

    &.featured-card {
        //padding: 1rem;
        border-radius: 0.25rem;

        .card-content {
            padding: 3rem 1rem;
        }

        .title, .subtitle {
            text-align: center;
            display: block;
        }

        .title {
            color: #ffffff;
        }

        .subtitle {
            color: rgba(255, 255, 255, 0.8);
        }
    }

    &.vert-card {
        margin: -$card-overflown-top 0 $card-overflown-top;
        height: 100%;
        padding: 0 $card-padding;

        .card-image {
            top: $card-overflown-top;
            margin-bottom: $card-overflown-top;
    
            border-radius: 10px;
            overflow: hidden;
        }
        
        figure.image {
            -webkit-transform: scale(1);
            transform: scale(1);
            transition: all .3s ease-in-out;
            
            // transition-duration: 0.3s;
            // transition-timing-function: ease-out;
            //transition-property: opacity,transform,filter;
        }

        &:hover {
            figure.image {
                -webkit-transform: scale(1.15);
                transform: scale(1.15);
            }
        }
    }

    &.flat-card, &.flat-color-card {
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: all .2s ease-in-out;

        &:hover {
            -webkit-transform: scale(1.024);
            transform: scale(1.024);
        }
    }

    &.flat-card {
        height: 100%;
        overflow: hidden;
        
        .subtitle {
            color: $studycle-green;
            font-weight: 700;
            text-transform: uppercase;
        }
        .card-content {
            padding: $card-padding;
        }
    }

    &.flat-color-card {
        height: 100%;
        padding: $card-padding $card-padding 0;
        flex-direction: column;

        p, div {
            //color: #5e5e5e;
            color: #ffffff;
        }
        

        .card-footer {
            border: 0;
            flex: 1;
            //justify-content: flex-end;
            align-items: flex-end;
            
        }

        &:hover {
            //background: $studycle-light-green;

            p, div {
                color: #ffffff;
            }
        }
    }

    &.horz-card {
        padding: $card-padding;

        .card-content{
            padding: 0;

            .content {
                //border-top: 2px solid $studycle-green;
                border-top: 2px solid $studycle-text-color-primary;
                padding-top: 1rem;

                > div {
                    flex-grow: 1;
                }

                .price {
                    color: $studycle-text-color-primary;
                    font-weight: 600;
                }

                .button {
                    align-self: flex-end;
                }
            }
        }
    }
}


@media screen and (min-width: $desktop), print {
.card {
    &.banner-card {
        border-radius: 0.5rem 10rem;
        padding: 3rem;

        .card-content {
            width: 75%;
            margin: 0 auto;
        }
    }
    &.featured-card {
        margin-bottom: 7rem;
    }
}
}