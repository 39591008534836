.page {
    //background: #edf0f2; f7f8fd
    letter-spacing: 0.2px;
    margin: 1.5rem 0 0 !important;

    .page-header {
        margin-bottom: 1rem;
    }

    .page-content {
        padding: 1rem 1.5rem;
    }

    .title {
        color: #000000;

        span {
            display: inline-block;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 0.75rem;
                width: 100%;
                z-index: -1;
                background: $studycle-yellow;
            }
        }
    }

    p.quote {
        border-left: 4px solid $studycle-blue;
        padding-left: 20px;
        line-height: 2.25rem;
    }

    .subtitle {
        color: $studycle-blue;
        font-weight: 700;
        //margin-bottom: 0;
    }


    .title {
        &:not(:first-child) {
            //margin-top: 2.5rem;
        }

        &.is-4 {
            //color: $studycle-blue;

        }
    }

    .blockquote {
        margin: 2rem 0 !important;
        background: $studycle-green-light;
        box-shadow: 0 3px 50px 0 rgba(105,102,130,.1);

        flex-wrap: wrap;
        text-align: center;
        justify-content: center;

        padding: 4rem 3rem;
        font-weight: 600;
        line-height: 2.75rem;
        font-style: italic;
        //border-radius: 8px;

        &.is-yellow {
            background: $studycle-yellow;
        }

        &.is-blue {
            background: $studycle-yellow;
            color: #fff;
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
}