.programmes-promo {
    background: url('/static/img/pages/programmes-background-blue.png') #62b3e6 center/cover no-repeat;

}

.featured-card {
    height: 100%;
    &.scl-early-years {
        background: url('/static/img/pages/early-years.png') $studycle-green bottom/contain no-repeat;

    }

    &.scl-academic-readiness {
        background: url('/static/img/pages/schoolers.png') $studycle-red bottom/contain no-repeat;

    }

    &.scl-career-skills {
        background: url('/static/img/pages/adults.png') #FCB233 bottom/contain no-repeat;

    }
}

@media screen and (max-width: $desktop - 1px) {
    .featured-card {

        &.scl-early-years,
        &.scl-academic-readiness,
        &.scl-career-skills {
            padding-bottom: 100px;

        }
    }
}