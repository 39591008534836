.button {
    border-radius: 0;
    padding: 16px 2.5rem;
    height: auto;
    font-weight: 700;

    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;

    //box-shadow: 0 0 0 3em $studycle-yellow inset;

    &.is-primary,
    &.is-light {
        &:hover {
//             color: $studycle-yellow !important;
//             box-shadow: 0 0 0 1px $studycle-yellow inset;
//             background-color: transparent !important;

             color: #000000;
             background-color: $studycle-yellow;
        }
    }
}