.course {
    font-size: 1.25rem;

    figure.promote-image {
        margin-bottom: 2rem;
    }

    .course-desc {
        h3 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        h4 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 0.25rem;
        }

        p, ul {
            margin-bottom: 1.5rem;
        }

        p {
            &.quote {
                border-left: 4px solid $studycle-blue;
                padding-left: 20px;
                line-height: 1.5;
            }
        }

        ul {
            list-style-type: disc;
            li {
                margin-left: 3rem;
            }
        }
    }

//     .course-info-container {
//         position: relative;
//         float: right;
//     }

    .course-info {
        padding: 1.25rem 1.5rem;
        font-size: 1rem;

        &.sticky {
            position: sticky;
            -webkit-position: sticky;
            position: sticky;
            top: 1.25rem;
        }

        h4 {
            font-weight: 600;
            margin-top: 1.5rem;
            margin-bottom: 0.25rem;
        }

        ul {
            li {
                $side-panel-indent: 1.75rem;

                position: relative;
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 1rem;
                margin-left: $side-panel-indent;

                span.icon {
                    display: inline-block;
                    position: absolute;

                    color: $studycle-blue;

                    height: 1.5rem;
                    width: 1.5rem;

                    left: -#{$side-panel-indent};

                    border-radius: 50%;
                    //marginTop: 1rem;
                    //margin-bottom: 1rem;
                    //background: data.color+"2B",
                }

                ul {
                    li {
                        margin: 0.25rem 1.5rem 0;
                    }
                }
                &.info-details {
                    margin-top: -0.5rem;
                    margin-left: 3rem;
                }
            }

            .target-age {
                .icon {
                    left: -1.5rem;
                    top: 0;
                }
            }

            &.price {
                li {
                    margin-bottom: 0.25rem;
                }
            }
        }

        a.button {
            padding: 8px 2.5rem;
        }
    }
}